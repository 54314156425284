import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/Home",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  //解决方案
  {
    path: "/Solution",
    name: "Solution",
    component: () => import("../views/Solution.vue"),
  },
  {
    path: "/home3",
    name: "Home3",
    component: () => import("../views/Home3.vue"),
    children: [
      {
        path: "a",
        component: () => import("../views/list/a.vue"),
      },
    ],
  },
  {
    path: "/Info",
    name: "Info",
    component: () => import("../views/Info.vue"),
  },
  //渠道合作
  {
    path: "/Connect",
    name: "Connect",
    component: () => import("../views/Connect.vue"),
  },
  {
    path: "/About",
    name: "About",
    component: () => import("../views/About.vue"),
  },
];

const router = new VueRouter({
  //   mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
