<template>
  <div>
    <!-- 头部 -->
    <div class="header">
      <div class="top" @mouseover="getShow()" @mouseleave="getOff()">
        <div class="top_0">
          <p v-show="onShow" style="color: black">
            “工作时间：周一至周五8:30-18:00”
          </p>
          <p v-show="onShow" style="color: black">
            “邮箱：gzsmile2020@163.com”、“电话：020-61796811”
          </p>
        </div>
        <div class="top_1">
          <div class="top_10">
            <el-image
              style="width: 300px; height: 50px; display: flex;left: -150px;"
              :src="require('@/assets/logo.png')"
            ></el-image>
          </div>
          <div class="top_11">
            <nav>
              <ul>
                <li
                  v-for="(item, i) in navData"
                  :class="{ nav_li: active === i }"
                  :key="i"
                  @click="$router.push({ path: item.path }), (active = i)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <router-view />
    <!-- 联系我们 -->
    <!-- <div class="contact">
        <div class="contact_1" @click="handleContact">联系我们</div>
    </div> -->
    <!-- 留言板 -->
    <div class="liuyan">
      <div class="liuyan_1" v-show="onShow1">
        <div class="liuyan_1_1">留言板</div>
        <div class="liuyan_1_2" @click="handleShow"></div>
      </div>
      <div class="liuyan_2" v-show="!onShow1">
        <div class="liuyan_2_1">
          <div class="liuyan_2_2">留言板</div>
          <div class="liuyan_2_3" @click="handleClose"></div>
        </div>
        <el-form
        style="margin:5px"
         :model="formLabelAlign"
              :rules="rules"
              action="https://formspree.io/f/xpzbqelw" method="post" id="myForm"
            >
              <el-form-item >
                <el-input type="textarea"  v-model="formLabelAlign.text" prefix-icon="el-icon-search" placeholder="请简单描述你的留言" name="留言"></el-input>
              </el-form-item>
              <el-form-item prop="name">
                <el-input v-model="formLabelAlign.name" prefix-icon="el-icon-user" placeholder="联系人" name="联系人"></el-input>
              </el-form-item>
              <el-form-item prop="phone">
                <el-input v-model="formLabelAlign.phone" prefix-icon="el-icon-phone" placeholder="请输入号码" name="手机号码"></el-input>
              </el-form-item>           
        </el-form>
        <div style="margin:auto;display: flex;justify-content: center;">
            <el-button style="background-color:#4e6ef2;color:#fff" @click="submitForm">发送</el-button>
        </div>
      </div>
    </div>
    <!-- 尾部 -->
    <div class="footer">
      <div class="footer_1">
        <div>
          <!-- 电话 -->
          <div class="footer_phone1">
            <el-image
              style="width: 60px; height: 60px"
              :src="require('@/assets/footer1.png')"
            ></el-image>
          </div>
          <div class="footer_phone2">
            <div class="">
              <span style="font-size: 18px"></span>
              <span style="color: rgb(157, 157, 157)">
                <span style="font-size: 18px">电话咨询</span>
                <div>
                  <span style="font-size: 24px">020-61796811</span>
                </div>
                <div>
                  <p style="color: rgb(68, 68, 68); font-size: 14px">
                    <span style=" ;"></span>
                  </p>
                  <span style="color: rgb(222, 222, 222)">
                    <p style="font-size: 14px"><b>文生：</b>13902386046</p>
                    <p style="font-size: 14px"><b>王生：</b>18138233853</p>
                  </span>
                  <p style="color: rgb(68, 68, 68); font-size: 14px">
                    <span style="color: rgb(68, 68, 68)"></span>
                  </p>
                </div>
              </span>
            </div>
          </div>

          <!-- 微信二维码 -->

          <div class="footer_img">
            <img src="../src/assets/微信公众号.png" alt="" />
          </div>
          <!-- 地址 -->
          <div class="footer_address">
            <div class="footer_address1">
              <span
                >地址：广东省广州市白云区丛云路839号<br />
                利都商务中心C座512室
              </span>
            </div>
            <div class="footer_address1">
              <span>客服邮箱：gzsmile2020@163.com </span>
            </div>

          <div class="footer_address1" style=" margin-top: 30px;">
              <a href="http://beian.miit.gov.cn/" style="color:white;text-decoration:none;  ">
                工业和信息化部备案管理系统网站 粤ICP备2021071569号</a>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      onShow: "false",
      onShow1: "false",
      formLabelAlign: {
        company:"",
        name: "",
        phone: "",
        email: "",  
        text:"",
      },
      rules:{ 
          name:[
              {required:true,message:'请输入你的姓名',trigger:'blur'},
     
          ],
          phone: [
            { required: true, message: '请输入正确手机号码', trigger: 'blur' },
            { min: 11, max: 11, message: '手机号码格式不正确', trigger: 'blur' }
          ],
          },
      active: 0,
      navData: [
        { name: "首页", path: "/Home" },
        { name: "解决方案", path: "/Solution" },
        
        { name: "动态资讯", path: "/Info" },
        { name: "渠道合作", path: "/Connect" },
        { name: "关于我们", path: "/About" },
      ],
    };
  },
  methods: {
      handleContact(){},
      submitForm() {
      var obj = $("#myForm").serialize(); //获取id为content的form表单里面的内容,name值均为formspree提供不能更改
      $.ajax({
        type: "post", //必须post请求
        url: "https://formspree.io/f/xpzbqelw",
        async: true,
        data: obj,
        dataType: "json",
        success: (res) => {
          this.$refs.formLabelAlign.resetFields();
          this.$message({
            message: "邮件已发送!!!",
            type: "success",
          });
        },
        error: (res) => {
          this.$message.error("未知错误,发送失败");
        },
      });
    },
    handleShow() {
      this.onShow1 = false;
    },
    handleClose() {
      this.onShow1 = true;
    },
    getShow() {
      this.onShow = true;
    },
    getOff() {
      this.onShow = false;
    },
    handleHome6() {
      this.$router.replace("/Home6");
    },
  },
  created() {
    this.$router.replace("/Home");
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
/* 顶部文字 */
.top .top_0 {
  display: flex;

  flex-direction: row;
  justify-content: space-between;
}
/* logo */
.top .top_1 .top_10 {
  position: absolute;
  left: 0;
  top: 30%;
}

/* 导航 */

.top .top_11 {
  background: #2b2b2b;
  position: absolute;
  top: 40%;
  right: 0;
}
.top .top_11 ul {
  list-style: none;
  width: 700px;
  height: 50px;
  line-height: 50px;
  margin: 0 auto;
  color: #fff;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}

.top .top_11 ul li {
  padding-left: 10px;
  padding-right: 10px;
  width: 100px;
  text-align: center;
}

/* 鼠标经过导航栏 */
.top .top_11 ul li:hover {
  cursor: pointer;
  background-color: #02b2b5;
}
/* 鼠标点击导航栏 */
.nav_li {
  background-color: #02b2b5;
}
/*  */
.header {
  background-color: white;
}
.top {
  margin: auto;
  position: relative;
  height: 120px;
  width: 1200px;
  background-color: white;
}

/* 联系我们 */



/* 留言板 */

/* 留言板初始状态 */
.liuyan {
  position: fixed;
  bottom: 25px;
  left: 25px;
}
.liuyan .liuyan_1 {
  width: 240px;
  height: 50px;
  background-color: #4e6ef2;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}
.liuyan .liuyan_1 .liuyan_1_1 {
  margin-left: 18px;
  width: 60px;
  line-height: 50px;
  font-size: 18px;
  color: white;
}
.liuyan .liuyan_1 .liuyan_1_1:hover {
  cursor: pointer;
}

.liuyan .liuyan_1 .liuyan_1_2 {
  margin-top: 15px;
  margin-right: 15px;
  width: 20px;
  height: 16px;
  border: 2px solid white;
}
.liuyan .liuyan_1 .liuyan_1_2:hover {
  cursor: pointer;
}
/* 留言板点击状态 */
.liuyan .liuyan_2 {
  width: 240px;
  height: auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 24px 24px 0 rgba(48, 55, 66, 0.3);
}
.liuyan .liuyan_2 .liuyan_2_1 {
  width: 240px;
  height: 50px;
  background-color: #4e6ef2;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}
.liuyan .liuyan_2 .liuyan_2_1 .liuyan_2_2 {
  margin-left: 18px;
  width: 60px;
  line-height: 50px;
  font-size: 18px;
  color: white;
}
.liuyan .liuyan_2 .liuyan_2_1:hover {
  cursor: pointer;
}

.liuyan .liuyan_2 .liuyan_2_1 .liuyan_2_3 {
  margin-top: 15px;
  margin-right: 15px;
  width: 20px;
  height: 10px;
  border-bottom: 2px solid white;
}
.liuyan .liuyan_2 .liuyan_2_1 .liuyan_2_3:hover {
  cursor: pointer;
}


/* 联系我们小功能 */
.contact {
    position:  fixed;
    top: 50%;
    right: 10px;}
.contact .contact_1{

    width:120px;
    height: 50px;
    border-radius: 10px;
    background-color: #4e6ef2 ;
    text-align: center;
    font-size: 24px;
    line-height: 50px;
    color: #fff;
}
.contact .contact_1:hover{
    cursor: pointer;
}

/* <底部样式> */
.footer {
  display: flex;
  justify-content: center;
  height: 250px;
  background-color: #212121;
}
.footer .footer_1 {
  position: relative;

  width: 1200px;
}

/* 联系方式 */
.footer .footer_1 .footer_phone1 {
  position: absolute;
  top: 70px;
  left: 30px;
}
.footer .footer_1 .footer_phone2 {
  position: absolute;
  top: 69px;
  left: 120px;
  width: 281px;
}

/* 二维码 */
.footer .footer_1 .footer_img {
  position: absolute;
  top: 10%;
  left: 44%;
}

.footer .footer_1 .footer_img img {
  width: 160px;
  height: 160px;
}
/* 地址 */
.footer .footer_1 .footer_address {
  position: absolute;
  top: 70px;
  left: 891px;
  width: 301px;
}

.footer .footer_1 .footer_address .footer_address1 {
  color: rgb(157, 157, 157);
  font-size: 14px;
}
</style>
